








import { Component, Vue } from 'vue-property-decorator';
import LocaleSelection from '@/components/LocaleSelection.vue';



@Component({
    name: 'LocaleSelectionPage',
    components: { LocaleSelection }
})
export default class LocaleSelectionPage extends Vue {
    private localeId: string;
    private selectedItem: string;
    private locale: string;
    private options: Array<{}>;
    private campaignData: Array<{}>;
    public authcode: Array<{ key: string; value: string }>;

    constructor() {
        super();
        this.localeId = '';
        this.locale = '';
        this.selectedItem = '';
        this.options = [];
        this.campaignData = [];
        this.authcode = [];

        //If the localeselection isn't needed then set the default locale. 
        //this.onSelectedItem("nl_nl");
    }

    onSelectedItem(selectedLocale: string): void {
        this.setLocale(selectedLocale);
        this.$router.push(`/${selectedLocale}/campaign-overview`);
    }

    setLocale(selectedLocale: string): void {
        this.$parent.$i18n.locale = selectedLocale;
    }
}
